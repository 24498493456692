.site-container {
  .checkout {
    .panel {
      .city-state-zip {
        .form-item {
          width: 30%;
          float: $ldirection;
          clear: none;
          margin-#{$rdirection}: 5%;
          margin-#{$ldirection}: 0;
          padding-#{$rdirection}: 0;
          &.postal-code {
            margin-#{$rdirection}: 0;
          }
          &.state {
            select {
              height: 58px;
            }
          }
        }
      }
    }
    &__index-content {
      .checkout {
        &__sidebar {
          @include breakpoint($bp--medium-up) {
            display: block;
          }
          &.display-footer {
            display: block;
            @include breakpoint($bp--medium-up) {
              display: none;
            }
            .shopping-bag-panel,
            .viewcart-panel {
              display: block;
            }
            .need-help-panel {
              display: block;
              margin-bottom: 0px;
              @include breakpoint($bp--medium-up) {
                display: block;
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
    .replen_notice {
      display: none;
    }
    .text--bold.price-range {
      position: relative;
      bottom: 15px;
      font-family: $ano-bold_regular-font;
      font-weight: 400;
      text-transform: uppercase;
      font-size: 18px;
    }
  }
  .checkout__content {
    .default-shipping,
    .edit-billing-radio,
    .edit-shipping-radio,
    .billing-address-form {
      label {
        display: inline-block;
      }
    }
    #shipping-panel {
      .content {
        .section-email-promotions {
          .form_element {
            .error {
              border: 1px solid $color--error;
            }
          }
        }
        #gift-options {
          word-wrap: break-word;
        }
        .gift-options {
          .checkbox {
            .picker-checkbox {
              display: block;
            }
          }
          input[type='text'],
          textarea {
            &[data-error] {
              border-color: $color--error;
            }
          }
        }
      }
    }
    .cart-items {
      &.product-list {
        .cart-item {
          .cart_item__error {
            float: right;
          }
          .product_name {
            margin-bottom: 0;
            width: 80%;
          }
          &__total {
            margin-top: 1em;
            margin-bottom: 60px;
          }
          &.sample {
            .cart-item__total {
              margin: 0;
            }
          }
        }
      }
    }
  }
  .order-summary-panel {
    .shipping {
      &.label,
      &.value {
        margin: 0;
      }
    }
  }
  .checkout-header {
    .top-viewcart-buttons {
      display: none;
    }
  }
  #bottom-viewcart-buttons {
    .continue-buttons {
      text-align: center;
      width: 100%;
    }
  }
  .checkout {
    .sign-in-panel {
      border-bottom: 1px solid $color--gray--lightest;
      .messages-container {
        padding: 0;
        background-color: transparent;
        border-width: 0;
        .single-message {
          color: $color--error;
          text-align: left;
        }
      }
    }
    .ui-widget-content {
      height: 12em;
      overflow-y: auto;
    }
    .left {
      .guarantee-panel {
        display: none;
      }
    }
  }
}

.checkout.panel {
  padding-bottom: 80px;
  .panel {
    .new-address {
      input:required[data-error],
      select:required[data-error],
      input:required.error,
      select:required.error {
        border-color: red !important;
      }
    }
  }
  .panel.finished {
    display: block;
  }
  .signin-panel {
    header {
      display: block;
    }
  }
}

.opc__payment .right.checkout__sidebar {
  display: block;
}

.checkout {
  #shipping-panel,
  .checkout-registration {
    input[type='text'] + label:before,
    input[type='tel'] + label:before,
    input[name='PASSWORD2'] + label:before {
      content: attr(data-required) attr(placeholder);
    }
  }
  .checkout-registration {
    .checkout-password-label {
      display: none;
    }
    input[name='PASSWORD'] + label:before {
      content: attr(placeholder);
    }
    input[name='PASSWORD'].js-label-mode + label:before {
      content: attr(alt);
    }
  }
  .billing-address-from-fields {
    .addresses,
    .phones,
    .address.qas {
      .form-item {
        width: 100%;
      }
    }
  }
  .cart-item {
    .cart-item__total {
      display: block;
      width: 15%;
    }
    .cart-item__replenishment {
      .replenishment__description {
        display: block;
      }
    }
  }
  .sample-select-button {
    display: none;
  }
  .disabled-button.btn {
    background-color: $color--gray;
  }
  &.active-panel-shipping {
    .site-header-formatter {
      z-index: 100;
    }
  }
}

.viewcart {
  .cart_item__error--hazmat {
    margin-top: 55px;
  }
}

.checkout__index-content {
  .cart_item__error--hazmat {
    margin-top: 150px;
  }
  .checkout__sidebar.display-footer {
    .shopping-bag-panel,
    .viewcart-panel {
      display: none;
    }
  }
  .registration-panel #continue-btn {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    margin: 0;
    padding: 0 15px 15px 15px;
    background: white;
    z-index: 100;
    input {
      width: 100%;
    }
  }
}

.checkout__sidebar {
  #links-panel {
    margin-top: 1em;
  }
  .tab-bar {
    display: none;
  }
}

.LPMcontainer {
  img {
    display: none;
  }
}

#confirmation-page {
  #registration-panel {
    .messages-container {
      float: none;
      display: block;
    }
    .how-we-will-use-container,
    .checkout-registration__show-password {
      display: none;
    }
  }
}

.active-panel-signin {
  section {
    #order-summary-panel,
    #shipping-panel,
    #payment-panel {
      display: none;
    }
  }
  .right.checkout__sidebar.display-footer {
    display: none;
  }
}

.active-panel-payment {
  .right.checkout__sidebar {
    display: block;
    #shopping-bag-panel,
    #order-summary-panel,
    #need-help-panel,
    #links-panel {
      display: none;
    }
  }
  .right.checkout__sidebar.display-footer {
    #need-help-panel,
    #links-panel {
      display: block;
    }
  }
}

.active-panel-shipping,
.active-panel-registration {
  .right.checkout__sidebar {
    display: block;
    #offer-code-panel,
    #order-summary-panel,
    #need-help-panel,
    #links-panel {
      display: none;
    }
  }
  .right.checkout__sidebar.display-footer {
    #need-help-panel,
    #links-panel {
      display: block;
    }
    #shopping-bag-panel {
      display: none;
    }
  }
}

.confirm {
  .account-nav {
    display: none;
  }
  .site-my-mac {
    display: none;
  }
}

section#promo-panel {
  display: none;
}

.checkout .cart-item__qty-label,
.checkout .cart-item__size-label {
  display: none;
}

#shipping-panel {
  .form-item.title {
    width: 100%;
  }
}

.active-panel-dramming,
.active-panel-stickers {
  .pg_wrapper {
    margin: 0 15px 90px;
    max-width: 1281px;
  }
  header {
    border-bottom: 1px solid $color--gray--lightest;
    text-align: center;
    h2.samples-panel__title {
      width: 100%;
      padding: 0.5em 0;
    }
    .stickers {
      .pink {
        color: $color--pink-tier1;
      }
      .blue {
        color: $color--blue--lighter;
      }
    }
  }
  .stickers_back a {
    width: 100%;
  }
  .product.clearfix {
    width: 48%;
    display: inline-block;
    margin: 5px 0;
    &.non-selected {
      pointer-events: none;
    }
    .product_name {
      margin-bottom: 2px;
    }
    .product__thumb {
      margin-right: auto;
      width: 100%;
      img {
        height: 200px;
      }
      .view-larger {
        display: none;
      }
    }
    .product__desc {
      width: 100%;
      margin-top: 10px;
      .sample-select-button {
        display: block;
        line-height: 35px;
        height: 35px;
        width: 60%;
        margin-left: 16%;
        padding: 0;
      }
    }
  }
}

.viewcart-panel__content {
  .product-list {
    .cart-item {
      &.virtualbundle {
        .product__desc {
          .product_name {
            width: 69%;
          }
        }
      }
    }
  }
}
