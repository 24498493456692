.loyalty__romance {
  &__with__rewards__details {
    .loyalty__level__1 {
      .benefits__details {
        bottom: 5%;
        @include breakpoint($bp--large-up) {
          bottom: 22%;
        }
        &__offers {
          width: 90%;
          .item__content {
            color: $color--full-white;
          }
        }
        .level__name,
        .level__text,
        .learn__more-link {
          color: $color--full-white;
          &:hover,
          &:visited,
          &:focus {
            color: $color--full-white;
          }
        }
      }
    }
    .loyalty__level__2,
    .loyalty__level__3 {
      .benefits__details {
        bottom: 6%;
        @include breakpoint($bp--large-up) {
          bottom: 22%;
          #{$ldirection}: 46%;
        }
        &__offers {
          width: 100%;
        }
        .item__content {
          @include breakpoint($bp--large-up) {
            max-width: 75px;
          }
        }
      }
    }
  }
}

#loyalty__page__index-bottom {
  visibility: visible;
}

.loyalty__panel__offers {
  &__sku__list-container {
    .tabbed-rewards-block__tab {
      width: 17%;
    }
  }
}

.loyalty__index__container {
  .loyalty__panel__offers {
    &__list-container,
    &__sku__list-container {
      .loyalty__panel__offer {
        &__title__details,
        &__sku__title__details {
          text-align: center;
          font-family: $ano-black_regular-font;
          text-transform: uppercase;
          span {
            border-bottom: 1px solid $color--gray--lighter;
            padding: 10px 20px;
          }
        }
      }
    }
  }
}

.mac-select__account-panel {
  &__header-left {
    float: #{$ldirection};
  }
  &__header-right {
    display: flex;
    flex-direction: column;
    position: absolute;
    #{$rdirection}: 50px;
  }
  &__content-spend,
  &__content-processing {
    clear: both;
  }
  &__content {
    position: relative;
  }
}

.account-nav {
  .account-nav__section {
    .mac-select__account-panel__content-title {
      width: 100%;
    }
  }
}

.checkout-panel--loyalty-join {
  .loyalty-message {
    margin-top: 40px;
  }
}

#loyalty__panel__offers {
  .loyalty__panel__offers__list {
    .loyalty__panel__offers {
      &__product {
        &__image-container,
        &__image {
          min-height: 325px;
          @include breakpoint($bp--large-up) {
            min-height: 340px;
          }
        }
        &__sub-header {
          margin-bottom: 10px;
          min-height: 37px;
        }
      }
    }
  }
  .loyalty__panel__offers {
    &__list-container {
      margin-top: 0;
      .slick-prev,
      .slick-next {
        top: 30%;
      }
    }
    &__offer-image {
      max-height: 300px;
    }
    &__loader__button {
      &.button--disabled {
        cursor: not-allowed;
      }
      &.not_meet,
      &.disable_product {
        background: $color--gray--lightest;
        color: $color--black;
        cursor: not-allowed;
      }
    }
  }
}

.mac-lover-content {
  .loyalty-message {
    padding-top: 20px;
  }
}

.mac-select__marketing-page {
  &__top-col1 {
    @include breakpoint($bp--medium-up) {
      width: 65%;
    }
  }
  &__top-col2 {
    @include breakpoint($bp--medium-up) {
      width: 35%;
    }
  }
}

#loyalty__page__index {
  .progress_bar {
    display: flex;
    @include swap_direction(padding, 0 8%);
    flex-direction: column;
    @include breakpoint($bp--medium-up) {
      flex-direction: row;
      margin-bottom: 20px;
    }
    &_right {
      position: relative;
      margin: 0 auto;
      .total_progress {
        position: absolute;
        top: 190px;
        #{$ldirection}: 90px;
      }
      .points__expiration {
        text-align: center;
      }
    }
    &_text {
      position: absolute;
      #{$ldirection}: 50px;
      top: 40px;
      .points_current {
        text-align: center;
      }
      .points_header {
        text-align: center;
      }
      .reward_status {
        width: 125px;
        word-break: break-word;
        margin: 5px;
        text-align: center;
      }
      .current_tier {
        text-align: center;
        &_level1 {
          color: $color--pink-tier1;
        }
        &_level2 {
          color: $color--red-tier2;
        }
        &_level3 {
          color: $color--gray-tier3;
        }
      }
    }
    &_left {
      width: 100%;
      @include breakpoint($bp--medium-up) {
        width: 50%;
      }
      .mac_lover_logo {
        max-width: 220px;
        margin-bottom: 20px;
      }
      &--header {
        font-size: 25px;
        margin-top: 20px;
      }
      &--info {
        text-transform: uppercase;
      }
      &--links-faq,
      &--links-about {
        font-family: $ano-black_regular-font;
        width: auto;
        text-decoration: underline;
        border-bottom: none;
        display: block;
        margin-bottom: 10px;
      }
    }
  }
}

.loyalty__panel__transactions {
  &__description {
    @include breakpoint($bp--medium-up) {
      width: 70%;
      margin: 0 auto;
    }
  }
  &__points__available {
    margin-top: 14px;
  }
}

.loyalty__enrollment__fields__input__text {
  &.password-field__loyalty {
    .field {
      margin-bottom: 10px;
    }
  }
}

.loyalty-select__bottom,
.loyalty__content-bottom {
  .mac-select__marketing-page {
    .macselect-benefits__container__content {
      .levels {
        height: auto;
      }
      &__left {
        .macselect-benefits__container__content {
          &__level1 {
            height: 901px;
          }
          &__level2 {
            height: 1013px;
          }
          &__level3 {
            height: 1006px;
          }
        }
      }
      &__right {
        .macselect-benefits__container__content {
          &__level1,
          &__level2,
          &__level3 {
            .levels-list {
              li {
                height: 110px;
                &::before {
                  height: auto;
                }
                &.custom__height1 {
                  height: 130px;
                }
                span {
                  display: block;
                }
              }
            }
          }
        }
      }
    }
  }
}

.site-footer--sticky {
  .mac-select-loyalty-footer-formatter {
    .col-md-2 {
      width: 30%;
    }
    #footer_sticky_chat_pc {
      position: relative;
      top: 5px;
      width: 40%;
      @include breakpoint($bp--medium-up) {
        width: auto;
      }
    }
  }
}

.loyalty__header-banner {
  text-align: center;
  .loyalty__header-banner--image {
    width: 100%;
    margin-bottom: 5%;
    @include breakpoint($bp--medium-up) {
      width: auto;
      margin-bottom: 0;
    }
  }
}

.loyalty-user-details__fieldset {
  .form-item_birthday {
    .field {
      &.error {
        border-color: $color--error;
      }
    }
  }
  .select2-container {
    &.field {
      &.error {
        border-color: $color--error !important;
      }
    }
  }
}

#loyalty__panel__offers__sku {
  .loyalty__panel__offers__sku {
    &__product__image-container {
      min-height: 320px;
      @include breakpoint($bp--large-up) {
        min-height: 340px;
      }
    }
    &__product__image {
      @include breakpoint($bp--large-up) {
        max-height: 300px;
      }
    }
    &__product__header {
      min-height: auto;
    }
    &__product__sub-header {
      height: 40px;
      overflow: hidden;
    }
    &__product__shade-container {
      min-height: 20px;
    }
    &__loader__button {
      margin: 0 auto;
    }
  }
}

#loyalty__panel__transactions {
  .loyalty__panel__transactions__list__column-spent {
    &.expando-menu-trigger {
      background-position: 10px !important;
    }
  }
}

.checkout__content {
  .panel--loyalty-join__logo {
    display: none;
  }
}

#confirmation-page {
  &.loyalty_join_confirmation-page {
    .checkout__content {
      &.left {
        position: relative;
      }
    }
    .panel--loyalty-join__logo {
      display: block;
      position: static;
      text-align: center;
      @include breakpoint($bp--large-up) {
        position: absolute;
        #{$ldirection}: 0;
        top: 8px;
        text-align: #{$ldirection};
        padding: 1em;
      }
      .loyalty__logo {
        @include breakpoint($bp--large-up) {
          width: 115px;
          height: 71px;
        }
      }
    }
    #registration-panel {
      margin-#{$ldirection}: auto;
      @include breakpoint($bp--large-up) {
        margin-#{$ldirection}: 15%;
      }
    }
    .registration-panel__title {
      font-size: 35px;
      letter-spacing: normal;
      line-height: 1.2;
    }
    .pc_loyalty_join_container {
      margin-bottom: 14px;
      .label {
        &.error {
          display: inline-flex;
          color: $color--error;
          a {
            color: $color--error;
          }
        }
      }
    }
  }
}

.elc-user-state-logged-in {
  #confirmation-page {
    &.loyalty_join_confirmation-page {
      .panel--loyalty-join__logo {
        position: static;
        float: none;
        @include breakpoint($bp--large-up) {
          float: $ldirection;
        }
      }
    }
  }
}
