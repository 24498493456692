#account-my-appointments {
  .appointments-container {
    .appointments-buttons {
      .virtual-change-button {
        display: inline;
      }
    }
  }
}

.appt-book-page-header {
  .appt-book-page-header-content {
    .has_hero_banner {
      padding-top: 69px !important;
    }
  }
}

@media only screen and (max-width: 767px) {
  .submenu__header {
    display: block !important;
  }

  .submenu--sticky.appointment-nav {
    .submenu__nav {
      display: block;
    }
  }

  .submenu__nav {
    background-color: #f1f2f2 !important;
    ul {
      display: block !important;
    }
    li {
      max-width: initial !important;
      a {
        margin-top: -3px !important;
      }
    }
  }

  li.jquery-once-1-processed:last-child {
    margin-top: -4px;
  }
}

.appt-book {
  .my-appointments {
    .appointments-container.current,
    #appt-book-my-appointments-no-appts {
      margin-top: 70px;
    }
  }
}
