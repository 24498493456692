@if $password_strengthen {
  .password-field {
    position: relative;
    &__info {
      @include breakpoint($bp--medium-up) {
        -webkit-transform: translateY(2%);
        -moz-transform: translateY(2%);
        -o-transform: translateY(2%);
        transform: translateY(2%);
        #{$ldirection}: 100%;
        margin-#{$ldirection}: 2%;
        width: 185px;
        &-checkout {
          bottom: 0;
          -webkit-transform: translateY(28%);
          -moz-transform: translateY(28%);
          -o-transform: translateY(28%);
          transform: translateY(28%);
          #{$ldirection}: 99%;
        }
      }
      &-checkout {
        display: flex;
      }
    }
    &__rules {
      li {
        color: $color--gray--dark;
      }
    }
  }
  .password-reset-page {
    .password-reset {
      .password-field {
        &__info {
          &-reset {
            @include breakpoint($bp--medium-up) {
              bottom: auto;
              #{$ldirection}: 320px;
              top: -22px;
            }
          }
        }
      }
    }
  }
}
