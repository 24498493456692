.account_payment {
  .payment_book_container {
    &.payment-info {
      margin-bottom: 15px;
      .payment-item {
        margin-top: 5px;
        a {
          margin: 0 10px 5px 0;
        }
      }
    }
  }
  .payment-form {
    padding: 10px;
    @include breakpoint($bp--large-up) {
      padding: 0;
    }
    .cards-div {
      width: 100%;
      @include breakpoint($bp--medium-portrait) {
        width: 70%;
      }
      @include breakpoint($bp--large-up) {
        width: 70%;
      }
      .payment-section {
        width: 100%;
        height: 65px;
        label {
          float: left;
          width: 100%;
          margin-top: 5px;
          display: block;
          @include breakpoint($bp--large-up) {
            width: 25%;
            margin-top: 10px;
          }
        }
        .input-field {
          float: left;
          height: 40px;
          border: 1px solid $color--gray--light;
          width: 100%;
          padding: 0 5px;
          @include breakpoint($bp--large-up) {
            width: 50%;
            margin: 0;
          }
        }
      }
      .expiry {
        .input-field {
          width: 48%;
          @include breakpoint($bp--medium-portrait) {
            width: 49%;
          }
          @include breakpoint($bp--large-up) {
            width: 24%;
          }
          &:first-of-type {
            margin-right: 4%;
            @include breakpoint($bp--large-up) {
              margin-right: 2%;
            }
          }
        }
      }
      .payment-form {
        &__submit {
          width: 50%;
          margin: 3% 0 0 25%;
        }
      }
    }
  }
  .responsive-container {
    margin-bottom: 50px;
    padding: 1em;
    @include breakpoint($bp--large-up) {
      margin-bottom: 0;
    }
  }
}
