body.page-product {
  @if $fonts_update == false {
    font-family: $roboto-mono_regular-font !important;
    font-size: 1rem !important;
  }
  #colorbox {
    #cboxWrapper {
      #cboxContent {
        #cboxLoadedContent {
          background-color: white;
        }
      }
    }
    &.welcome15-overlay {
      #cboxWrapper {
        #cboxContent {
          #cboxLoadedContent {
            background-color: black;
          }
        }
      }
    }
  }
  .product {
    &--full {
      .product {
        &__footer {
          .product {
            &__add-to-bag {
              width: 92%;
              margin: 0 16px;
            }
          }
        }
      }
    }
  }
}

.site-footer {
  .social-links {
    .icon--pinterest {
      display: block;
    }
  }
}

.site-header {
  .site-header__tools {
    .block-template-site-email-signup-au-v1 {
      display: none;
    }
  }
}

body.fixedpos {
  width: 100%;
}

.prod_inv_status-2 {
  .product__footer {
    .product__inventory-status {
      .temp-out-of-stock {
        display: block;
      }
    }
  }
}

.prod_inv_status-3 {
  .product__footer {
    .product__add-to-bag {
      &.coming-soon {
        display: none !important;
      }
    }
  }
}

.waitlist-iframe-wrapper {
  width: 95%;
  height: 290px;
  margin: 17px 7px 5px 7px;
}

.tiny-waitlist-overlay {
  .waitlist_header {
    font-size: 20px;
  }
  .waitlist_thankyou_heading {
    font-size: 20px;
  }
  .waitlist_thankyou_message {
    margin-top: 10px;
  }
}

.field {
  .email_input {
    float: left;
    width: 62%;
  }
  .align-r {
    float: left;
    margin-left: 10px;
    .input-btn {
      height: 58px;
    }
  }
  .terms_condition_cont {
    float: right;
    margin: 10px 5px 0 0;
  }
}

.site-container {
  .product__footer {
    a.notify_me {
      padding: 20px 0;
      width: 100%;
      margin: 0 !important;
      position: static;
    }
  }
  .grid--mpp__item {
    .product--teaser {
      .product__footer {
        .product__inventory-status {
          > li {
            text-align: right;
            float: none;
          }
          .temp-outof-stock_text,
          .comming-soon_text {
            margin-top: 5px;
          }
        }
      }
    }
  }
  .page--spp__product {
    .product--not-shoppable {
      .product__footer {
        .product__inventory-status {
          .coming-soon_text {
            position: relative;
            font-size: 18px;
            text-align: center;
          }
        }
      }
    }
  }
  .store-locator {
    .form--search--advanced {
      .form--search {
        padding: 0 0 74px;
      }
    }
  }
  .page--spp__product {
    .prod_inv_status-3 {
      .product__detail {
        .product__footer {
          .product__inventory-status {
            .coming-soon {
              display: block;
            }
          }
        }
      }
    }
  }
}

.contact_us_page {
  .site-container {
    min-height: 515px;
  }
}

.shades--12 .shade-box {
  cursor: pointer;
}

.site-container {
  .page--spp__product {
    .product__price {
      width: 100%;
    }
    .product__price-installment {
      position: initial;
    }
  }
}

.site-footer {
  .afterpay-content,
  .afterpay-content-mb {
    display: none;
  }
  .site-footer--bottom {
    .at-h6__sitefooter {
      display: none;
    }
  }
}

.grid--mpp__item {
  .product--teaser {
    .product__footer {
      .product__inventory-status {
        .coming-soon_text {
          display: block;
          position: relative;
        }
        .notify-status {
          display: none;
        }
      }
    }
  }
  .prod_inv_status-3 {
    .product__inventory-status {
      .coming-soon {
        display: block;
        text-align: center !important;
        font-size: 18px;
      }
    }
  }
}

@media only screen and (max-width: 1022px) {
  .product--full {
    .product__footer {
      .product__inventory-status {
        display: block;
      }
    }
  }
}

.page--spp__product {
  .product__inventory-status {
    .js-inv-status-3 {
      .notify-status {
        display: none;
      }
    }
  }
}

.foundation-finder {
  .product {
    &--full {
      .product {
        &__footer {
          .product__price {
            &-installment {
              margin: 0 0 11px 15px;
              position: initial;
              img {
                width: auto;
                max-width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

.invis-search {
  &__container {
    .invis-form {
      &__select--radius {
        width: 42%;
        font-size: 12px;
        padding-#{$rdirection}: 12px;
      }
      &__input--zip {
        width: 55%;
        @include swap_direction(padding, 8px 0 0 12px);
      }
      &__geo-container {
        display: inline-block;
        width: 100%;
        .icon--geolocation {
          font-size: 24px;
          margin-#{$rdirection}: 10px;
        }
        .geo_search {
          width: 100%;
          padding: 0;
        }
      }
      &__or-text {
        display: block;
        text-align: center;
        font-size: 13px;
        margin: 7px 0;
      }
      &__btn--submit {
        display: inline-block;
        width: 100%;
        overflow: hidden;
      }
    }
    .invis-results {
      &__messages {
        padding-#{$ldirection}: 0;
      }
    }
    .search__row {
      margin: 10px 0;
    }
    .invis-sku {
      &__container {
        .invis-sku {
          &__image {
            width: 17%;
          }
          &__name {
            width: 80%;
          }
        }
      }
    }
  }
}

.invis--app {
  &__container {
    .invis-start {
      &__panel {
        .icon--geolocation {
          display: none;
        }
      }
    }
  }
}

.prod_inv_status-7 {
  .product__footer {
    .product__inventory-status {
      .sold-out {
        display: block;
      }
    }
  }
}
