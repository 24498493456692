.site-my-mac {
  .gnav {
    .social-login {
      &__terms {
        padding: 0;
      }
    }
  }
}

.social-login {
  .picker-label {
    float: none;
    text-align: $ldirection;
    margin-#{$ldirection}: 39px;
  }
  &__divider {
    .inner {
      padding: 5px 6px;
    }
  }
}
/* Profile preference page */
.social-info {
  padding: 0 20px 0 10px;
  margin: 0 auto;
  width: 100%;
  max-width: 1281px;
  &__connect {
    line-height: 20px;
    .fb-login-button {
      vertical-align: middle;
    }
  }
}
/* SignIn page styling */
.sign-in-page {
  .social-login {
    &__email-opt-in,
    &__terms {
      padding: 0;
      text-align: $ldirection;
      margin-top: 0;
      @include breakpoint($bp--medium-up) {
        padding-top: 20px;
      }
      @include breakpoint($bp--xxlarge-up) {
        padding-#{$ldirection}: 51px;
      }
    }
  }
  @include breakpoint($bp--medium-up) {
    margin-bottom: 100px;
    .new-account,
    .return-user {
      margin-#{$rdirection}: 20px;
    }
    .field-container--grid .form-item {
      float: none;
      margin-#{$rdirection}: 0;
      padding-#{$rdirection}: 0;
      clear: both;
    }
    .sign-in-container {
      width: 50%;
    }
    .social-login-section {
      @include swap_direction(margin, 160px 0 0 50px);
      @include breakpoint($bp--xxlarge-up) {
        margin-#{$ldirection}: 0;
      }
      .social-login {
        &__terms {
          display: block;
        }
      }
    }
    .divider {
      .social-login__divider {
        position: static;
        &:before {
          #{$ldirection}: 100%;
        }
        .inner {
          #{$ldirection}: inherit;
        }
      }
    }
  }
  .divider {
    .social-login__divider {
      .inner {
        @include breakpoint($bp--medium-up) {
          // Altered (or) text divider, to display at center.
          #{$rdirection}: -22px;
        }
      }
    }
  }
}
/* Checkout page styling */
.checkout {
  &__content {
    .signin-panel,
    .sign-in-panel {
      .social-login {
        &__terms {
          text-align: $ldirection;
          padding: 0;
          margin-top: 0;
        }
        &__opt-in-label {
          display: block;
          margin-top: 0;
        }
        &__divider {
          margin: 4px auto 12px;
          @include breakpoint($bp--medium-up) {
            margin: 0;
          }
        }
      }
    }
  }
}
/* Write a review page styling */
#power_review_container {
  .social-login {
    &__email-opt-in {
      padding: 0;
    }
  }
}
