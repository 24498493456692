.checkout {
  #payment-panel {
    border-bottom: none;
    .payment-edit-content {
      input[type='text'] input[type='tel'] input[name='PASSWORD2'] {
        & + label:before {
          content: attr(data-required) attr(placeholder);
        }
      }
    }
    #checkout_complete {
      .payment-option {
        &.radio {
          display: block;
          @include breakpoint($bp--medium-up) {
            width: 80%;
          }
          .adyen-payment {
            margin-top: 10px;
            input[name='storeDetails'] {
              -webkit-appearance: checkbox;
            }
            .chckt-button {
              &--submit {
                border-color: $color--gray;
                background-color: $color--black;
              }
            }
            .chckt-pm {
              &__pending-message {
                border-color: $color--gray;
                background-color: $color--black;
              }
            }
          }
        }
      }
      .payment-type {
        input[type='radio'] {
          -webkit-appearance: radio;
          vertical-align: middle;
        }
        .radio {
          label {
            display: inline-block;
            line-height: 1.25;
          }
        }
      }
    }
    &.payment-panel {
      .payment-type {
        &.field-container {
          &--grid {
            .form-item {
              &:nth-of-type(2n + 1),
              &:nth-of-type(2n + 2) {
                @include breakpoint($bp--large-up) {
                  width: 100%;
                  float: none;
                  clear: both;
                }
              }
            }
          }
        }
      }
    }
    .payment-panel {
      &__confirm_text {
        margin-bottom: 15px;
      }
    }
  }
}
