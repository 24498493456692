.site-header-formatter {
  .header-nav-section__section {
    @include breakpoint($bp--largest-up) {
      padding: 0 12px;
    }
  }
}

.field-content {
  .faq {
    margin: auto;
    &__headline {
      padding-left: 5%;
      width: 97%;
      &-toggle {
        padding-right: 10%;
      }
    }
    &__content {
      p {
        margin-left: 5%;
        margin-right: 5%;
        width: 82%;
        text-align: justify;
      }
    }
  }
}

.field-elc-mpp-content {
  .faq {
    margin: auto;
    &__headline {
      padding-left: 5%;
      width: 97%;
      &-toggle {
        padding-right: 10%;
      }
    }
    &__content {
      p {
        margin-left: 5%;
        margin-right: 5%;
        width: 82%;
        text-align: justify;
      }
    }
  }
}

.site-bag {
  &__contents {
    .cart-product {
      .kit-container {
        .cart-item__kit-item {
          .product-name {
            display: block;
          }
        }
      }
    }
  }
}
