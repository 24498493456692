.site-container {
  .account-nav {
    .sidebar-profile-pic {
      float: none !important;
    }
  }
  .address-book-page__content {
    padding: 15px;
  }
  .profile-page__content {
    .birthday_col {
      .form_birthday {
        label {
          display: block;
        }
      }
      .gender_col {
        label {
          display: block;
        }
      }
    }
    .newsletter-info {
      .newsletter-info__fieldset {
        .field-container {
          .form-item {
            .picker {
              .picker-label {
                max-width: 85%;
              }
            }
          }
        }
      }
    }
  }
  .account {
    .ui-widget-content {
      height: 12em;
      overflow-y: auto;
    }
  }
  .sign-in-page {
    padding: 20px 20px 0 10px;
    .error_messages {
      display: inline-block;
      margin-bottom: 5px;
    }
  }
  #address {
    .address-overlay-shipping {
      select.error,
      input[type='text'].error,
      input[type='tel'].error {
        border: 1px solid $color--error;
      }
    }
  }
  .email-signup-new-user {
    margin: 0 auto !important;
    padding: 0 10px;
    #email_new_user_signup {
      .picker-checkbox {
        .picker-handle {
          margin: 0 10px 0 0px;
        }
        .picker-label {
          width: 88%;
        }
      }
    }
  }
  .contact-us-page {
    .user-data,
    #email_an_artist {
      width: 100% !important;
      select {
        margin-bottom: 23px;
      }
    }
    .email-artists-form {
      padding: 0 10px;
      #email_an_artist {
        textarea.error {
          border: 1px solid red;
          box-shadow: none;
        }
      }
    }
    .customer-service-title {
      width: auto;
      float: none;
      margin-bottom: 20px;
    }
    .customer-service-description {
      width: auto;
      float: none;
    }
    input[type='text'],
    input[type='email'] {
      width: 100% !important;
      margin: 0 0 23px;
    }
  }
  .account-order-history {
    .past-purchases {
      .past-purchases__item {
        .order-status {
          .order-status {
            margin-left: 0% !important;
          }
        }
      }
    }
  }
  .custom-grid__item {
    .text-header {
      width: 70%;
      border-right: 1px solid white;
      h3 {
        width: 74%;
      }
    }
    .logo-img {
      width: 25%;
      position: relative;
      float: right;
      padding-right: 11px;
      padding-top: 10px;
      text-align: center;
    }
    .logo-txt {
      text-align: center;
      color: white;
    }
  }
}

.contact-us-page {
  margin-bottom: 0px !important;
  .contact-nav {
    margin-bottom: 0px !important;
    li {
      display: inline-block;
      float: left;
      vertical-align: text-top;
      margin-bottom: -1px;
      min-height: 250px;
      padding: 0 0 20px 0;
      text-transform: uppercase;
      border-bottom: 1px solid #7f7f7f;
    }
  }
}

.site-header {
  .site-header__tools {
    .block-template-site-email-signup-au-v2 {
      display: none;
    }
  }
}

.product__footer {
  .product__link-custom {
    width: 100%;
  }
}

.grid--mpp__item {
  .product--teaser {
    .product__footer {
      .product__inventory-status {
        a.notify_me {
          height: 60px;
          line-height: 60px;
        }
      }
    }
  }
}

.picker {
  label.error {
    color: #b84947;
  }
}

.address__content {
  padding: 0px 20px 10px 10px;
  .address-form__header {
    font-size: 3rem;
  }
}

.replenishment-detail-page {
  .products-list__list {
    .js-rep-details {
      display: none;
    }
  }
}

.order-details {
  .order-details-page {
    margin: 0 10px;
  }
}

.address-form {
  form[name='address'] {
    .form-item {
      width: 48%;
      display: inline-block;
    }
    .city_container {
      float: right;
    }
    .address1_container {
      width: 49%;
      position: absolute;
      padding: 2px 15px 0 0;
      .form-item {
        width: 100%;
      }
    }
    .address2_container {
      width: 49%;
      float: right;
      padding-right: 2px;
      .form-item {
        width: 100%;
      }
    }
    .postal_code_container {
      input[name='POSTAL_CODE'] {
        width: 60%;
      }
    }
    .city-state-zip {
      .form-item {
        width: 30%;
        float: $ldirection;
        clear: none;
        margin-#{$rdirection}: 4%;
        margin-#{$ldirection}: 0;
        padding-#{$rdirection}: 0;
        &.postal-code {
          margin-#{$rdirection}: 0;
        }
        &.state {
          select {
            height: 60px;
          }
        }
      }
    }
    .address_title_label,
    .qas_container {
      width: 100%;
      padding-right: 8px;
    }
    .default_ship_container label {
      display: inline-block;
    }
    .form_text {
      margin-bottom: 10px;
    }
  }
}

.order-details-page {
  .order-products {
    .virtual-bundling {
      .cart-item__desc {
        margin-#{$ldirection}: 0;
      }
      .cart-product-kit-shades {
        padding: 0;
      }
      .kit-container {
        @include swap_direction(padding, 15px 0 0 0);
        margin: 0;
        .cart-item {
          &__desc-info {
            @include swap_direction(margin, 0 0 20px 0);
            @include swap_direction(padding, 0 0 20px 0);
            border-bottom: 1px solid #{$color--black};
            &:last-child {
              border-bottom: none;
              margin: 0;
              padding: 0;
            }
          }
        }
        .swatch {
          border-radius: 50%;
          display: inline-block;
          height: 22px;
          position: relative;
          top: 6px;
          width: 22px;
        }
      }
    }
  }
}
