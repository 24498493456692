.contact-us-page {
  &.mobile {
    #contact-nav {
      li {
        min-height: auto;
      }
    }
  }
  &.pc {
    .contact-nav {
      margin: 0 auto;
      max-width: 800px;
      li {
        width: 33.33%;
        margin: 0;
        float: left;
        clear: none;
        padding-right: 2em;
        min-height: 200px;
        .details {
          font-size: 12px;
          border-bottom: 0;
          min-height: auto;
          word-break: break-word;
          #customer_service_chat {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

#customer_service_MC_chat {
  display: inline-block;
  .title {
    border-top: 0;
    padding: 0;
    margin: 0;
  }
}
