.click-and-collect-method-select {
  float: left;
  margin-bottom: 20px;
  width: 100%;
  @include breakpoint($bp--small-down) {
    margin-bottom: 15px;
    width: auto;
    .auspost-overlay.button {
      margin: 20px;
    }
  }
  .radio-button {
    float: left;
    width: 45%;
    @include breakpoint($bp--small-down) {
      width: 100%;
      .picker-label {
        max-width: 80%;
      }
    }
  }
  .auspost-logo {
    float: right;
    width: 55%;
    margin-top: 1em;
    @include breakpoint($bp--small-down) {
      display: none;
    }
  }
  label {
    text-transform: none;
    span {
      display: inline-block;
    }
  }
  .auspost-label {
    font-weight: bold;
    @include breakpoint($bp--small-down) {
      margin-left: 10px;
      max-width: 90%;
    }
  }
  .auspost-note {
    display: inline-block;
    margin-left: 22px;
    margin-top: 10px;
  }
  .auspost-overlay.button {
    margin: 15px;
    display: inline-block;
    height: 45px;
    line-height: 3;
  }
  .auspost-address-display {
    p {
      margin: 0;
    }
  }
  #aupost-root {
    margin: 20px 0 0 -70px;
    @include breakpoint($bp--small-down) {
      margin: 20px 0 0;
    }
  }
}

#colorbox.auspost-overlay {
  .auspost-continue {
    width: 100%;
    .location-selected {
      margin: 20px auto;
      display: block;
      width: 150px;
    }
  }
  #cboxClose {
    visibility: hidden;
    &:before {
      visibility: visible;
      font-size: 22px;
      font-family: icons;
      content: '\E019';
    }
  }
}
